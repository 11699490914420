import React, { useState } from "react";
import { css } from "@emotion/core";
import { useStaticQuery, graphql } from "gatsby";
import Promo from "../../svgs/promo.svg";
import Container from "../../components/Container";
import Button from "../../components/Button";
import { Desktop, Tablet } from "../../constants/responsive";
import { useIntl } from "gatsby-plugin-intl";
import Slider from "react-slick";

const PromotionBar = () => {
  const intl = useIntl();

  const [open, setOpen] = useState(true);

  const data = useStaticQuery(graphql`
    query {
      allHomepageNotification {
        edges {
          node {
            name
            title
            description
            image
            link
            date
            locale
            button_text
          }
        }
      }
    }
  `);

  /* const notification = data.allHomepageNotification.edges
    .map((node) => node.node)
    .filter((notification) => notification.locale === intl.locale); */

  const allNotifications = data.allHomepageNotification.edges
    .map((node) => node.node)
    .filter((notification) => notification.locale === intl.locale);

  if (allNotifications.length === 0) {
    return null;
  }

  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        height: ${open ? 150 : 0}px;
        background: #e4e4e4;
        width: 100%;
        transition: all 0.2s ease;
        overflow: hidden;
        z-index: 2;
        ${Tablet} {
          height: ${open ? 90 : 0}px;
        }
      `}
    >
      {/* 提示5秒一滚 */}
      <Slider
        infinite
        autoplay
        autoplaySpeed={5000}
        css={css`
          width: 100%;
        `}
      >
        {allNotifications.map((notification, index) => {
          return (
            <Container key={index}>
              <div
                css={css`
                  display: flex;
                  position: relative;
                  width: 100%;
                  justify-content: flex-start;
                  align-items: flex-start;
                  flex-direction: column;
                  height: 150px;
                  ${Tablet} {
                    justify-content: space-between;
                    align-items: center;
                    flex-direction: row;
                    padding-right: 60px;
                    height: 100%;
                  }
                  ${Desktop} {
                    justify-content: center;
                    align-items: center;
                    padding-right: 0px;
                    height: 100%;
                  }
                `}
              >
                <div
                  css={css`
                    height: 90px;
                    position: absolute;
                    right: 0px;
                    bottom: 0px;
                    ${Tablet} {
                      position: static;
                    }
                  `}
                >
                  <img height={90} src={notification.image} />{" "}
                  {/* 图片地址链接到cms接口 */}
                  {/* <Promo height={90} /> */}
                </div>

                <div
                  css={css`
                    margin: 0;
                    padding: 20px 0px;
                    width: calc(100% - 40px);
                    height: 88px;
                    ${Tablet} {
                      padding: 0px 20px;
                      width: 360px;
                      height: auto;
                    }
                    ${Desktop} {
                      padding: 0px 40px;
                      width: auto;
                    }
                  `}
                >
                  {notification.title}
                </div>
                <a
                  // href={notification.link}
                  target="_blank"
                  onClick={() => {
                    // 如果从外部链接传入qd则改变notification.link中原有的qd值
                    if (sessionStorage.qd && notification.link.includes("qd")) {
                      var qdStartIndex = notification.link.indexOf("qd");
                      var qdEndIndex = notification.link.indexOf(
                        "&",
                        qdStartIndex
                      );
                      var replaceStr = notification.link.slice(
                        qdStartIndex,
                        qdEndIndex
                      );
                      notification.link = notification.link.replace(
                        replaceStr,
                        `qd=${sessionStorage.qd}`
                      );
                    }
                    window.location.href = notification.link;
                  }}
                >
                  <Button text={notification.button_text} outline />
                </a>
                <div
                  css={css`
                    position: absolute;
                    top: 30px;
                    right: 0px;
                    transform: translateY(-50%);
                    cursor: pointer;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    ${Tablet} {
                      top: 50%;
                    }
                  `}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <div
                    css={css`
                      width: 20px;
                      height: 2px;
                      background: #707070;
                      transform: rotate(45deg);
                      position: absolute;
                    `}
                  />
                  <div
                    css={css`
                      width: 20px;
                      height: 2px;
                      background: #707070;
                      transform: rotate(-45deg);
                      position: absolute;
                    `}
                  />
                </div>
              </div>
            </Container>
          );
        })}
      </Slider>

      {/* <Container>
        <div
          css={css`
            display: flex;
            position: relative;
            width: 100%;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            height: 100%;
            ${Tablet} {
              justify-content: space-between;
              align-items: center;
              flex-direction: row;
              padding-right: 60px;
            }
            ${Desktop} {
              justify-content: center;
              align-items: center;
              padding-right: 0px;
            }
          `}
        >
          <div
            css={css`
              height: 90px;
              position: absolute;
              right: 0px;
              bottom: 0px;
              ${Tablet} {
                position: static;
              }
            `}
          >
            <Promo height={90} />
          </div>

          <div
            css={css`
              margin: 0;
              padding: 20px 0px;
              width: calc(100% - 40px);
              height: 88px;
              ${Tablet} {
                padding: 0px 20px;
                width: 360px;
                height: auto;
              }
              ${Desktop} {
                padding: 0px 40px;
                width: auto;
              }
            `}
          >
            {notification.title}
          </div>
          <a href={notification.link} target="_blank">
            <Button text={notification.button_text} outline />
          </a>
          <div
            css={css`
              position: absolute;
              top: 30px;
              right: 0px;
              transform: translateY(-50%);
              cursor: pointer;
              width: 30px;
              height: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              ${Tablet} {
                top: 50%;
              }
            `}
            onClick={() => {
              setOpen(false);
            }}
          >
            <div
              css={css`
                width: 20px;
                height: 2px;
                background: #707070;
                transform: rotate(45deg);
                position: absolute;
              `}
            />
            <div
              css={css`
                width: 20px;
                height: 2px;
                background: #707070;
                transform: rotate(-45deg);
                position: absolute;
              `}
            />
          </div>
        </div>
      </Container> */}
    </div>
  );
};

export default PromotionBar;
