import React from 'react';
import { css } from '@emotion/core';
import { Desktop, Tablet } from '../../constants/responsive';
import JumpTo from '../JumpToLink';

const Banner = ({ banner = {} }) => {
  // 产品同事会在某些字段内容最后加上十六进制颜色值（#FFFFFF），代表该段文字颜色，前端对这些字段做处理。
  const colorMap = {
    title: '#000000',
    description: '#000000',
    substract: '#000000',
    addition: '#000000',
  };
  // 不改变原来接口获得的特殊字段的数据。创建一个临时存储特殊字段值的对象，HTML内显示的文本内容取用该对象字段的值
  const specialBannerTerms = {
    title: banner.title,
    description: banner.description,
    substract: banner.substract,
    addition: banner.addition,
  };
  for (let key in banner) {
    if (colorMap.hasOwnProperty(key)) {
      // 分离文字内容与颜色值
      let index = banner[key].lastIndexOf('#');
      if (index !== -1) {
        colorMap[key] = banner[key].slice(index);
        specialBannerTerms[key] = banner[key].slice(0, index);
      }
    }
  }
  return (
    // 背景颜色
    <div
      css={css`
        position: relative;
        height: 601px;
        width: 100%;
        display: flex;
        flex-direction: column;
        ${Tablet} {
          background: ${banner['back_color']};
          height: 491px;
          width: 100%;
        }
        ${Desktop} {
          height: 615px;
          width: 100%;
        }
      `}
    >
      {/* 背景图 */}
      <div
        css={css`
          height: 100%;
          width: 100%;
          display: flex;
          background: url(${banner.image}) no-repeat;
          background-size: cover;
          background-position: center 50%;
          ${Tablet} {
            flex-direction: row;
            justify-content: center;
            background: url(${banner.ipad_image}) no-repeat;
            background-size: 100% 100%;
            background-position: right;
          }
          ${Desktop} {
            background: url(${banner.pc_image}) no-repeat;
            background-size: 100% 100%;
            background-position: right;
          }
        `}
      >
        {/* 限制最大宽度，居中显示 */}
        <div
          css={css`
            position: relative;
            color: #ffffff;
            width: 100%;
            height: 100%;
            display: flex;
            padding: 150px 10px 0 19px;
            ${Tablet} {
              max-width: 768px;
              padding: 113px 5px 0 51px;
            }
            ${Desktop} {
              max-width: 1440px;
              padding: 142px 10px 0 67px;
            }
          `}
        >
          {/* 文字内容 */}
          <div
            css={css`
              ${Tablet} {
              }
              ${Desktop} {
                max-width: 38%;
              }
            `}
          >
            {/* 摘要 */}
            {banner.substract && (
              <div
                css={css`
                  color: ${colorMap['substract']};
                  text-align: center;
                  border: 1px solid ${colorMap['substract']};
                  border-radius: 6px;
                  width: fit-content;
                  height: auto;
                  line-height: 1;

                  padding: 7px 10px;
                  font-weight: normal;
                  font-size: 12px;
                  margin-bottom: 10px;
                  ${Tablet} {
                    padding: 8px 15px;
                    font-weight: normal;
                    font-size: 16px;
                    margin-bottom: 10px;
                  }
                  ${Desktop} {
                    padding: 6px 30px;
                    font-weight: normal;
                    font-size: 20px;
                    margin-bottom: 20px;
                  }
                `}
                dangerouslySetInnerHTML={{
                  __html: specialBannerTerms.substract,
                }}
              ></div>
            )}
            {/* 标题 */}
            <div
              css={css`
                color: ${colorMap['title']};
                font-weight: bold;
                font-size: 32px;
                line-height: 36px;
                margin-bottom: 20px;
                ${Tablet} {
                  max-width: 517px;
                  font-weight: bold;
                  font-size: 36px;
                  line-height: 48px;
                  margin-bottom: 10px;
                }
                ${Desktop} {
                  max-width: 554px;
                  font-weight: bold;
                  font-size: 48px;
                  line-height: 52px;
                  margin-bottom: 20px;
                }
              `}
              dangerouslySetInnerHTML={{ __html: specialBannerTerms.title }}
            ></div>
            {/* 描述 */}
            <div
              css={css`
                color: ${colorMap['description']};
                font-weight: normal;
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 120px;
                ${Tablet} {
                  max-width: 384px;
                  font-weight: normal;
                  font-size: 18px;
                  line-height: 24px;
                  margin-bottom: 40px;
                }
                ${Desktop} {
                  font-weight: normal;
                  font-size: 24px;
                  line-height: 30px;
                  margin-bottom: 20px;
                }
              `}
              dangerouslySetInnerHTML={{
                __html: specialBannerTerms.description,
              }}
            ></div>
            {/* 按钮 */}
            <JumpTo url={banner.link}>
              <div
                css={css`
                  background: ${banner['button_back_color']};
                  border-radius: 41px;
                  color: ${banner['button_text_color']};
                  text-align: center;
                  width: fit-content;

                  height: 35px;
                  padding: 0 15px;
                  font-weight: bold;
                  font-size: 14px;
                  line-height: 35px;
                  ${Tablet} {
                    height: 35px;
                    padding: 0 20px;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 35px;
                  }
                  ${Desktop} {
                    height: 47px;
                    padding: 0 35px;
                    font-weight: bold;
                    font-size: 18px;
                    line-height: 47px;
                  }
                `}
              >
                {banner.button_text}
              </div>
            </JumpTo>
            {/* 底部附加文字 */}
            {banner.addition && (
              <JumpTo url={banner['addition_link']}>
                <div
                  css={css`
                    color: ${colorMap['addition']};
                    cursor: pointer;
                    text-decoration: underline;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: normal;
                    position: absolute;
                    bottom: 30px;
                    left: 30px;
                    ${Tablet} {
                      left: auto;
                      position: absolute;
                      bottom: 28px;
                      font-weight: normal;
                      font-size: 12px;
                      line-height: normal;
                    }
                    ${Desktop} {
                      position: absolute;
                      bottom: 34px;
                      font-weight: normal;
                      font-size: 12px;
                      line-height: normal;
                    }
                  `}
                >
                  {specialBannerTerms.addition}
                </div>
              </JumpTo>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
