import { css } from '@emotion/core';
import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { mainColor } from '../../constants/colors';
import { Desktop, Tablet } from '../../constants/responsive';
import { useCookies } from 'react-cookie';
import { useMount, useSessionStorage } from 'react-use';
import { useIntl } from 'gatsby-plugin-intl';

import { set } from 'lodash';

const PromotionPopup = () => {
  const intl = useIntl();
  const [show, setShow] = useState(false);
  // const [cookies, setCookie] = useCookies(["cookie-name"]);
  const [popupDate, setPopupDate] = useSessionStorage('promotionPopupDate', '');
  const data = useStaticQuery(graphql`
    query {
      allPromotionPopup {
        edges {
          node {
            name
            title
            description
            image
            link
            date
            locale
            button_text
          }
        }
      }
    }
  `);
  const popup = data.allPromotionPopup.edges
    .map((node) => node.node)
    .find((popup) => popup.locale === intl.locale);

  useMount(() => {
    // if (popup) {
    //   if (cookies["promotionPopupDate"] !== popup.date) {
    //     setShow(true);
    //   }
    //   setCookie("promotionPopupDate", popup.date);
    // }
    if (popup) {
      if (popupDate !== popup.date) {
        setShow(true);
      }
      setPopupDate(popup.date);
    }
  });

  if (!popup) {
    return null;
  }

  return (
    <div
      css={css`
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: ${show ? 'flex' : 'none'};
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.6);
        z-index: 9999;
      `}
    >
      <div
        css={css`
          background: ${mainColor};
          border-radius: 12px;
          position: relative;
          display: flex;
          overflow: hidden;
          width: 90%;
          flex-direction: column;
          ${Tablet} {
            flex-direction: row;
            width: 600px;
            height: 300px;
          }
          ${Desktop} {
            width: 900px;
            height: 475px;
          }
        `}
      >
        <div
          css={css`
            flex: 0 0 50%;
            height: 30vh;
            ${Tablet} {
              height: auto;
            }
          `}
        >
          <img
            src={popup.image}
            css={css`
              width: 100%;
              height: 100%;
              object-fit: cover;
            `}
          />
        </div>
        <div
          css={css`
            flex: 0 0 50%;
            display: flex;
            align-items: center;
            padding: 20px;
            ${Desktop} {
              padding: 40px;
            }
          `}
        >
          <div
            css={css`
              color: white;
            `}
          >
            <h2
              css={css`
                display: block;
              `}
            >
              {popup.title}
            </h2>
            <h5
              css={css`
                display: block;
              `}
            >
              {popup.description || 'Missing description'}
            </h5>
            <a
              // href={popup.link}
              target='_blank'
              css={css`
                display: inline-block;
              `}
              onClick={() => {
                setShow(false);
                // 如果从外部链接传入qd则改变popup.link中原有的qd值
                if (sessionStorage.qd && popup.link.includes('qd')) {
                  var qdStartIndex = popup.link.indexOf('qd');
                  var qdEndIndex = popup.link.indexOf('&', qdStartIndex);
                  var replaceStr = popup.link.slice(qdStartIndex, qdEndIndex);
                  popup.link = popup.link.replace(
                    replaceStr,
                    `qd=${sessionStorage.qd}`
                  );
                }
                window.location.href = popup.link;
              }}
            >
              <div
                css={css`
                  background: white;
                  padding: 6px 16px;
                  border-radius: 30px;
                  color: ${mainColor};
                  font-weight: bold;
                  font-size: 12px;
                  user-select: none;
                  ${Desktop} {
                    font-size: 20px;
                    padding: 10px 30px;
                  }
                `}
              >
                {popup.button_text}
              </div>
            </a>
          </div>
        </div>
        <div
          css={css`
            width: 30px;
            height: 30px;
            cursor: pointer;
            position: absolute;
            right: 20px;
            top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
          onClick={() => {
            setShow(false);
          }}
        >
          {[1, -1].map((i) => {
            return (
              <div
                key={i}
                css={css`
                  background: white;
                  width: 30px;
                  height: 2px;
                  position: absolute;
                  transform: ${`rotate(${i * 45}deg)`};
                `}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PromotionPopup;
