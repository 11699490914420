import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PromotionBar from '../components/PromotionBar';
import HomeBanner from '../components/HomeBanner';
import HomeWhy from '../components/HomeWhy';
import PromotionPopup from '../components/PromotionPopup';
import SignUpSuccessPopup from '../components/SignUpSuccessPopup';
import HomeDownload from '../components/HomeDownload';
import { css } from '@emotion/core';
import HomeInvestSmarter from '../components/HomeInvestSmarter';
import GradientBackground from '../components/GradientBackground';
import Blank from '../components/Blank';
import { Desktop, Tablet } from '../constants/responsive';

const IndexPage = () => {
  const intl = useIntl();
  return (
    <Layout footerForm>
      <SEO
        title={intl.formatMessage({ id: 'home.SEO.title' })}
        description={intl.formatMessage({ id: 'home.SEO.description' })}
        keywords={intl.formatMessage({ id: 'home.SEO.keywords' })}
        home
      />
      <SignUpSuccessPopup />
      <PromotionPopup />
      <PromotionBar />
      <HomeBanner />
      <HomeDownload />
      <HomeInvestSmarter />
      <Blank height={[90, 64, 151]} />
      <HomeWhy
        title={intl.formatMessage({ id: 'home.also.why-trusted-title' })}
        nameList={['licensed', 'tech', 'trusted']}
        namePrePath={'home.also'}
        titleStyle={css`
          font-size: 24px;
          font-weight: 500;
          line-height: 1.33;
          text-align: center;
          ${Tablet} {
            text-align: left;
            font-size: 36px;
            font-weight: 500;
            line-height: 1.33;
            width: 603px;
          }
          ${Desktop} {
            font-size: 36px;
            font-weight: 500;
            line-height: 0.92;
            width: auto;
          }
        `}
      />

      {/* 渐变背景 */}
      <GradientBackground
        height={[612, 452, 517]}
        background={`linear-gradient(to bottom, rgba(242, 242, 242, 0) , #F2F2F2 )`}
        marginTop={[-512, -348, -311]}
        css={css`
          z-index: -1;
        `}
      />
    </Layout>
  );
};

export default IndexPage;
