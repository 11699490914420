import React from 'react';
import { css } from '@emotion/core';
import { Desktop, Tablet } from '../../constants/responsive';
import Slider from 'react-slick';
// import SmartStockBanner from './SmartStockBanner';

import Banner from './Banner';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';
const HomeBanner = () => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      allHomeBanner {
        edges {
          node {
            id
            addition
            addition_link
            back_color
            button_back_color
            button_text
            button_text_color
            description
            image
            ipad_image
            link
            locale
            pc_image
            substract
            title
          }
        }
      }
    }
  `);
  const banners = data.allHomeBanner.edges
    .map((node) => node.node)
    .filter((homebanner) => {
      return homebanner.locale === intl.locale;
    });
  return (
    <>
      <div
        css={css`
          background: #f2f2f2;
          width: 100%;
          overflow: hidden;
          -webkit-mask-image: -webkit-radial-gradient(white, black);
          .slick-dots {
            transform: translateY(-30px);
          }
          .slick-dots li button:hover:before,
          .slick-dots li button:focus:before {
            color: #ffffff;
            opacity: 0.4;
          }
          .slick-dots li button:before {
            opacity: 1;
            color: #000000;
            font-size: 9px;
          }
          ${Tablet} {
            .slick-dots li button:before {
              font-size: 13px;
            }
          }
          ${Desktop} {
            .slick-dots li button:before {
              font-size: 16px;
            }
          }
          .slick-dots li.slick-active button:before {
            color: #ffffff;
            opacity: 1;
          }
        `}
      >
        <Slider dots infinite autoplay autoplaySpeed={5000}>
          {banners.map((banner) => {
            return <Banner banner={banner} key={`home-banner-${banner.id}`} />;
          })}
          {/* 主题股票组合的banner,也就是轮播图最后一张 */}
          {/* <SmartStockBanner /> */}
        </Slider>
      </div>
    </>
  );
};

export default HomeBanner;
