import React from 'react';
import { css } from '@emotion/core';
import { Desktop, Tablet } from '../../constants/responsive';
import Section from '../Section';
import ScrollContainer from 'react-indiana-drag-scroll';
import Container from '../Container';
import Option from './Option';
import { useIntl } from 'gatsby-plugin-intl';
import InvestSmarter from '../../components/InvestmentOptions';
import Blank from '../../components/Blank';

const HomeInvestSmarter = () => {
  const intl = useIntl();
  return (
    <>
      <Container
        css={css`
          margin-top: 50px;
          padding: 0 25px;
          ${Tablet} {
            margin-top: 100px;
            padding: 0 30px;
          }
          ${Desktop} {
            margin-top: 150px;
            padding: 0 20px;
          }
        `}
      >
        <div
          css={css`
            font-size: 24px;
            font-weight: 500;
            line-height: 1.33;
            ${Tablet} {
              font-size: 36px;
              line-height: 1.33;
            }
            ${Desktop} {
              font-size: 36px;
              line-height: 0.67;
            }
          `}
        >
          {intl.formatMessage({ id: 'home.invest-smarter.title' })}
        </div>
        <div
          css={css`
            margin-top: 10px;
            font-size: 14px;
            font-weight: normal;
            line-height: 1.71;
            ${Tablet} {
              font-size: 18px;
              line-height: 1.11;
              color: #161616;
              margin-top: 20px;
              width: 574px;
            }
            ${Desktop} {
              font-size: 24px;
              line-height: 1.38;
              width: 942px;
              margin-top: 28px;
              color: #161616;
            }
          `}
        >
          {intl.formatMessage({ id: 'home.invest-smarter.description' })}
        </div>
        <Blank height={[10, 38, 50]} />
        {/* <InvestSmarter /> */}
      </Container>
      <div
        css={css`
          width: 100%;
          // padding-left: 27px;
          ${Tablet} {
            // padding-left: 30px;
            max-width: 1024px;
          }
          ${Desktop} {
            padding-left: 117px;
            max-width: 1440px;
          }
        `}
      >
        <InvestSmarter />
      </div>
      {/* 【产品详情一级入口卡片】- WEB端 */}
      {/* <div
        css={css`
          display: none;
          ${Desktop} {
            display: flex;
            margin-top: -232px;
            // width: 1200px;
            // justify-content: space-between;
            margin-top: 50px;
          }
        `}
      >
        <Option name='LongTerm' />
        <Option name='Thematic' />
        <Option name='StockTrading' />
        <Option name='CashManagement' />
      </div> */}
      {/* 【产品详情一级入口卡片】- IAPD端、MOBILE端 */}
      {/* <ScrollContainer
        css={css`
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-top: 20px;
          margin-left: 25px;
          ${Tablet} {
            // display: none;
            // max-width: 648px;
            margin-left: 30px;
            margin-top: 20px;
          }
          ${Desktop} {
            display: none;
          }
        `}
      >
        <Option name='LongTerm' />
        <Option name='Thematic' />
        <Option name='StockTrading' />
        <Option name='CashManagement' />
      </ScrollContainer> */}
    </>
  );
};

export default HomeInvestSmarter;
